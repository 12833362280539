import { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import PriceConverter, { convertCurrency } from '../../helpers/PriceConverter'
import PopularCourses from './PopularCourses'
import API from '../../../api'
import get from 'lodash/get'
import find from 'lodash/find'
import { APP_LANGUAGE, createPadiCourseUrl } from '../../../localized-urls'
import Loader from '../../helpers/Loader'
import PropTypes from 'prop-types'

const getNormalizedLanguage = () => {
  switch (APP_LANGUAGE) {
    case 'zh-hant':
      return 'zh-HK'
    case 'zh-hans':
      return 'zh-CN'
    default:
      return APP_LANGUAGE
  }
}

const Courses = ({ popularCourses, selectedCurrency }) => {
  const [courses, setCourses] = useState([])
  const [allCourses, setAllCoursesList] = useState([])
  const [activeFilter, setActiveFilter] = useState()
  const [isLoading, setLoading] = useState(true)
  const isInitialMount = useRef(true)

  const onFilterClick = (filter) => setActiveFilter(filter)

  useEffect(function() {
    let apiCalls = []

    Object.values(popularCourses)?.forEach((item) => {
      const { title } = item
      apiCalls.push(
        API(`dive-guide/products/${title}`).get({
          priceCountry: 'US',
          locale: getNormalizedLanguage(),
        }),
      )
    })
    Promise.all(apiCalls)
      .then((values) => {
        let coursesList = []
        let impressions = []
        const lang = getNormalizedLanguage()
        values.forEach(({ product }, index) => {
          const elem = product?.masterData?.current
          const attributes = get(elem, 'masterVariant.attributesRaw')
          const image = get(elem, 'masterVariant.images[0].url')
          const title = get(elem, 'name')
          const price = get(elem, 'masterVariant.price.value')
          const excerpt = find(attributes, { name: 'excerpt' })
          const experienceLevel = find(attributes, {
            name: 'experience-level',
          })
          const isBeginner =
            get(experienceLevel, 'value[0].key') ===
            'experience-level-beginner'
          const description = get(
            excerpt,
            `value[${lang}]`,
            get(excerpt, 'value.en'),
          )
          const priceAmount = price ? price?.centAmount / 100 : 0
          const convertedPrice = priceAmount
            ? convertCurrency(priceAmount, price.currencyCode, selectedCurrency)
            : 0
          const impression = {
            name: get(elem, 'name.en'),
            id: elem?.id,
            price: convertedPrice.toFixed(2),
            brand: 'PADI',
            category: `elearning course${!price ? ' (unavailable)' : ''}`,
            list: window.currentPageType,
            position: index + 1,
          }
          if(title) {
            impressions.push(impression)
            coursesList.push({
              img: image,
              price: price ? (
                <PriceConverter
                  amount={priceAmount}
                  currency={price.currencyCode}
                />
              ) : (
                ''
              ),
              subTitle: 'Devil’s Den',
              time: '',
              title,
              id: product?.id,
              isBeginner,
              impression,
              url: createPadiCourseUrl(product['key']),
              description,
            })
          }
        })
        // pushToDataLayer({
        //   event: 'impression',
        //   ecommerce: {
        //     currencyCode: selectedCurrency,
        //     impressions,
        //   },
        // })
        setCourses(coursesList)
        setAllCoursesList(coursesList)
      })
      .finally(() => {
        setLoading(false)
        isInitialMount.current = false
      })
  }, [])

  useEffect(() => {
    if(!isInitialMount.current) {
      if(activeFilter === 'beginner') {
        setCourses(allCourses.filter((item) => item?.isBeginner))
      } else if(activeFilter === 'advanced') {
        setCourses(allCourses.filter((item) => !item.isBeginner))
      } else {
        setCourses(allCourses)
      }
    }
  }, [activeFilter])

  if(isLoading) {
    return (
      <div className='react-app-loading mth'>
        <Loader />
      </div>
    )
  }

  return (
    <PopularCourses
      courses={courses}
      handleClick={onFilterClick}
      activeFilter={activeFilter}
    />
  )
}

export default connect((state) => ({
  selectedCurrency: state.selectedCurrency,
}))(Courses)

Courses.propTypes = {
  popularCourses: PropTypes.object,
  selectedCurrency: PropTypes.object,
}
