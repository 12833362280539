import { createStore, combineReducers } from 'redux'
import { configure } from '../api'
import { Provider } from 'react-redux'
import { IntlProvider } from 'react-intl'
import { selectedTab } from '../actions/dive-guide-actions'
import {
  selectedCurrency,
  setCurrency,
  pageLoader,
} from '../actions/main-actions'
import {
  currencyDirective,
  hideMobileAppPromotion,
  convertDjangoRenderedPrice,
  diveGuidesHeaderFooter,
  showModalOnClick,
  collapseItems,
  breadcrumbsToggle,
} from '../directives'
import ErrorHandler from '../components/ErrorHandler'

import { getLanguage, getTranslations } from 'utils'
import { toast } from 'react-toastify'
import 'babel-polyfill'
import 'whatwg-fetch'
import 'react-toastify/dist/ReactToastify.css'

import ReactPlayer from 'react-player'
import HeroSearch from '../components/homepage/Hero'
import CalendarContainer from '../components/dive-guides/marine-calendar/CalendarContainer'
import HighCharts from '../components/dive-guides/HighCharts'
import HeroLightbox from '../components/liveaboard/HeroLightbox'
import WhereToDive from '../components/dive-guides/map/WhereToDive'
import BookDiving from '../components/dive-guides/book-diving/BookDiving'
import ShopTypesToggle from '../components/dive-guides/ShopTypesToggle'
import SliderDg from '../components/dive-guides/SliderDg'
import PadiSearch from '../components/dive-guides/PadiSearch'
import Courses from '../components/dive-guides/padi-courses/Courses'
import { tabsArray } from '../components/dive-guides/book-diving/tabs'
import '@formatjs/intl-getcanonicallocales/polyfill'
import '@formatjs/intl-locale/polyfill'
import '@formatjs/intl-pluralrules/polyfill'
import '@formatjs/intl-pluralrules/locale-data/en'
import 'finally-polyfill'
import { createRoot } from 'react-dom/client'

if(process.env.SENTRY_KEY) {
  Sentry.init({
    dsn: process.env.SENTRY_KEY,
    ignoreErrors: [
      'AbortError',
      'UnhandledRejection',
      'Unexpected token o in JSON at position 1',
      'Anonymous function(/gtm.js)',
      "undefined is not an object (evaluating 'd.appendChild')",
      'chrome-extension',
      'gtm',
      'Illegal invocation',
      "Can't find variable: setIOSParameters",
      '(app-modern)',
      'd is undefined',
    ],
  })
}

let store = createStore(
  combineReducers({
    pageLoader,
    selectedTab,
    selectedCurrency,
  }),
)

let language = getLanguage()
let messages = {}
toast.configure()

window.store = store
configure(store)

// window.fx = fx

if(language !== 'en') {
  getTranslations(language, renderApp)
} else {
  renderApp()
}

function renderApp(translatedStrings) {
  if(translatedStrings) {
    messages = translatedStrings
  }
  renderElement('homepage-hero-search', HeroSearch, {
    searchLaUrl: window.searchLaUrl,
    isDiveGuides: true,
  })

  renderElement('hero-lightbox', HeroLightbox, {
    photos: window.photos,
  })

  renderElement('video-player', ReactPlayer, {
    url: window.video && window.video.url,
    key: '1',
    volume: 1,
    loop: true,
    playing: false,
    controls: true,
    muted: false,
    width: '100%',
    height: '100%',
    className: 'player',
    config: {
      vimeo: {
        playerOptions: { background: true },
      },
    },
  })

  renderElement('high-charts-country', HighCharts, {
    type: 'country',
    highChartsCountry: window.highChartsCountry,
  })

  renderElement('shop-types', ShopTypesToggle)

  renderElement('book-diving', BookDiving, {
    location: {
      type: window.locationType,
      id: window.locationId,
      slug: window.locationSlug,
    },
    tabs: tabsArray,
  })

  renderElement('top-locations', SliderDg, {
    data: window.topLocations,
    view: 3,
    name: 'top-locations',
  })

  renderElement('popular-courses', Courses, {
    popularCourses: window.popularCourses,
  })

  renderElement('calendar-container', CalendarContainer, {
    data: window.marineLife,
  })

  renderElement('where-to-dive', WhereToDive, {
    pins: window.divePlaces,
    locationBounds: window.locationBounds,
    countryCode: window.countryCode,
  })

  renderElement('padi-search', PadiSearch)
  renderElement('padi-search-mobile', PadiSearch)

  hideMobileAppPromotion()
  currencyDirective(function(currency) {
    store.dispatch(setCurrency(currency))
  })
  collapseItems()
  diveGuidesHeaderFooter()
  showModalOnClick(renderElement)
  convertDjangoRenderedPrice(renderElement)
  breadcrumbsToggle()
}

function renderElement(elem, ComponentToRender, props) {
  let element
  if(typeof elem === 'string') {
    element = document.getElementById(elem)
  } else {
    element = elem
  }

  if(element) {
    const root = createRoot(element)
    root.render(
      <IntlProvider
        locale={language === 'ach' ? 'en' : language}
        messages={messages}
        textComponent='span'
      >
        <Provider store={store}>
          <ErrorHandler>
            <ComponentToRender {...props} />
          </ErrorHandler>
        </Provider>
      </IntlProvider>,
    )
  }
}
