import { SubmissionError } from 'redux-form'
import isPlainObject from 'lodash/isPlainObject'
import each from 'lodash/each'
import upperFirst from 'lodash/upperFirst'
import storage from 'store2'
import { serializeData, getCookie, getIdToken } from './utils'
import { toast } from 'react-toastify'

let domain = process.env.DOMAIN
let chinaDomain = process.env.CHINA_DOMAIN

if(
  window.location.href.search('travel.padi.localhost') !== -1 &&
  !storage('testMode')
) {
  domain = 'https://travel.padi.localhost'
}

// FIXME make it as middleware
export function configure(s) {
  window.store = s
}

export default function(
  endpoint,
  signal,
  addTokenToRequest,
  endpointAsUrl,
  currencyHeader,
  chinaApi,
) {
  return new API(
    endpoint,
    signal,
    addTokenToRequest,
    endpointAsUrl,
    currencyHeader,
    chinaApi,
  )
}

const handlerError = (error) => {
  each(error, function(errorMessage, key) {
    if(key === 'nonFieldErrors' || key === 'isDraft' || key === '__all__') {
      toast.error(
        Array.isArray(errorMessage) ? errorMessage[0] : errorMessage,
        {
          position: toast.POSITION.TOP_CENTER,
        },
      )
    } else if(isPlainObject(errorMessage)) {
      handlerError(errorMessage)
    } else if(Array.isArray(errorMessage) && isPlainObject(errorMessage[0])) {
      handlerError(errorMessage)
    } else {
      const modifiedKey = key
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        .split(' ')
        .map((word, index) =>
          index === 0 ? upperFirst(word) : word.toLowerCase(),
        )
        .join(' ')

      toast.error(`${modifiedKey} : ${errorMessage}`, {
        position: toast.POSITION.TOP_CENTER,
      })
    }
  })
}

class API {
  constructor(
    endpoint,
    signal,
    addTokenToRequest,
    endpointAsUrl,
    currencyHeader,
    chinaApi,
  ) {
    this.endpoint = endpoint
    this.signal = signal || undefined
    this.addTokenToRequest = addTokenToRequest
    this.endpointAsUrl = endpointAsUrl
    this.currencyHeader = currencyHeader
    this.chinaApi = chinaApi
  }

  getUrl() {
    if(this.endpoint.includes('https://')) {
      return this.endpoint
    } else if(this.endpoint.includes('/api/adventure/v1')) {
      return `${domain}${this.endpoint}`
    }
    if(this.chinaApi) {
      return `${chinaDomain}${this.endpoint}`
    } else {
      return this.endpointAsUrl
        ? this.endpoint
        : `${domain}/api/v2/travel/${this.endpoint}`
    }
  }

  request(method = 'GET', data, options = {}) {
    const authToken = getIdToken()
    const isPadiAPI =
      this.endpoint.includes('auth/api/oauth/refresh') ||
      this.endpoint.includes('acquia-stage.padi.com') ||
      this.endpoint.includes('api-ecomm.')
    const Authorization = authToken && !isPadiAPI ? 'Bearer ' + authToken : ''

    let url = this.getUrl()
    url += url.endsWith('/') || url.endsWith('.json') ? '' : '/'

    if(method === 'GET' && data) {
      url += `?${serializeData(data)}`
    }

    let headers = {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    }

    if(Authorization) {
      headers['Authorization'] = Authorization
    }

    if(!isPadiAPI) {
      headers['X-CSRFToken'] = getCookie('csrftoken')
    }

    if(this.currencyHeader) {
      headers = {
        ...headers,
        'Currency-code': this.currencyHeader,
      }
    }

    if(options.isFormData) {
      let { 'Content-Type': toDelete, ...newHeaders } = headers
      headers = newHeaders
      var formData = new window.FormData()

      for(let name in data) {
        formData.append(name, data[name])
      }
    }
    return window
      ?.fetch(url, {
        method,
        body:
          data && method !== 'GET'
            ? options.isFormData
              ? formData
              : JSON.stringify(data)
            : undefined,
        headers,
        signal: this.signal,
        credentials: isPadiAPI ? 'omit' : 'include',
      })
      ?.then((response) => {
        if(response.status === 500) {
          toast.error('Server error. Please try again later.', {
            position: toast.POSITION.TOP_CENTER,
          })
          throw new Error('500')
        }

        if(response.status === 401) {
          // store.dispatch(logout());
          window.localStorage.removeItem('user')
          window.localStorage.removeItem('token')

          //   if (window.location.pathname.search(createURL('login')) >= 0) {
          //     store.dispatch(push(createURL('login')));
          //     window.location.reload();
          //   }
        }

        if(response.status === 400) {
          return response.json().then((error) => {
            if(response.ok) {
              return error
            }

            handlerError(error)
            // eslint-disable-next-line no-throw-literal
            throw {
              status: response.status,
              error,
            }
          })
        }

        if(response.status === 403) {
          throw new Error('403')
        }

        if(response.status === 404) {
          // notification.error({
          //   message: 'Not found',
          //   style: {
          //     width: 300,
          //     textTransform: 'uppercase',
          //     marginLeft: 335 - 300,
          //   },
          // });
          return response.json().then(function(error) {
            // eslint-disable-next-line no-throw-literal
            throw {
              status: 404,
              error,
            }
          })
        }

        const contentTypeHeader =
          response.headers && response.headers.get('Content-Type')
        if(
          response.status === 204 ||
          !contentTypeHeader ||
          (contentTypeHeader &&
            contentTypeHeader.search('application/json') === -1)
        ) {
          return response
        }

        return response.json().then(function(body) {
          if(response.ok) {
            return body
          }
          handlerError(body)
          throw body
        })
      })
  }

  postAsFormData(data) {
    return this.request('POST', data, { isFormData: true })
  }

  patchAsFormData(data) {
    return this.request('PATCH', data, { isFormData: true })
  }

  putAsFormData(data) {
    return this.request('PUT', data, { isFormData: true })
  }

  post(data) {
    return this.request('POST', data)
  }

  delete(data) {
    return this.request('DELETE', data)
  }

  patch(data) {
    return this.request('PATCH', data)
  }

  put(data) {
    return this.request('PUT', data)
  }

  get(data) {
    return this.request('GET', data)
  }

  set404ErrorMsg(error) {
    this.notFoundEror = error

    return this
  }

  all(requests) {
    return Promise.all(requests).catch((body) => {
      let errorText = ''

      each(body.errors, (val, key) => {
        errorText += `${key} - ${val}. `
      })

      throw new SubmissionError(errorText)
    })
  }
}
